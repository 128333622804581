import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/service";
export const PageQuery_service_nl_frontendmdx = graphql`
        query PageQuery_service_nl_frontendmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "service"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/frontend/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Front-end",
  "path": "/service/frontend",
  "date": "2022-10-15T00:00:00.000Z",
  "template": "Service",
  "language": "nl",
  "icon": "bx-user-pin",
  "tags": ["ontwikkelaar", "medior", "senior"],
  "experience": [{
    "title": "Website Freelancer",
    "task": "Ontwikkelaar",
    "tags": ["ontwikkelaar"],
    "outcome": ["Beheer van meertalige content", "Toekomstgericht en uitbreidbaar", "Generatie van pagina's op server"],
    "link": ["https://www.cheesebyte.nl"],
    "image": ["./images/frontend/cb-site-01.png", "./images/frontend/cb-site-02.png", "./images/frontend/cb-site-03.png"],
    "copyright": "Cheesebyte"
  }, {
    "title": "Race Tracker",
    "task": "Ontwikkelaar",
    "tags": ["ontwikkelaar", "tweede lead"],
    "outcome": ["Voorspelling en verbetering prestaties", "Complexe componenten voor statistieken", "Realtime volgen van spelers", "Bidirectionele datastromen"],
    "link": null,
    "copyright": null
  }, {
    "title": "Revalidatie In 3D",
    "task": "Hoofdontwikkelaar Front-end / Pijplijn",
    "tags": ["lead", "ontwikkelaar", "unity3d"],
    "outcome": ["Apps voor Android en iOS", "Apps voor macOS en Windows", "Maatwerk GUI met uGUI", "Interne apps"],
    "link": null,
    "image": ["./images/mobile/tq-app-client-01.jpg"],
    "copyright": "TweeQ LLC"
  }, {
    "title": "Tijdregistratie",
    "task": "Hoofdontwikkelaar",
    "tags": ["ontwikkelaar"],
    "outcome": ["Export & beveiligde opslag", "Automatische synchronisatie", "Desktop app & achtergrondservice", "Doorlopende registratie van uren", "Kostenbesparing bij afhandeling uren"],
    "link": null,
    "copyright": null
  }]
};
const layoutProps = {
  PageQuery_service_nl_frontendmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "pixel-perfecte-ui",
      "style": {
        "position": "relative"
      }
    }}>{`Pixel-perfecte UI`}<a parentName="h1" {...{
        "href": "#pixel-perfecte-ui",
        "aria-label": "pixel perfecte ui permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Vector, pixel of 9-patch? Deze vragen kan ik allemaal met 'ja' beantwoorden.`}</p>
    <h2 {...{
      "id": "vaardigheden",
      "style": {
        "position": "relative"
      }
    }}>{`Vaardigheden`}<a parentName="h2" {...{
        "href": "#vaardigheden",
        "aria-label": "vaardigheden permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`5+ jaar web UI ervaring`}</li>
      <li parentName="ul">{`10+ jaar ervaring met UI in het algemeen`}</li>
      <li parentName="ul">{`Fan van component-gebaseerde ontwerpen`}</li>
      <li parentName="ul">{`Kennis van gangbare UX / UI-principes`}</li>
    </ul>
    <h2 {...{
      "id": "technisch",
      "style": {
        "position": "relative"
      }
    }}>{`Technisch`}<a parentName="h2" {...{
        "href": "#technisch",
        "aria-label": "technisch permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`Desktop: Win32, MFC, Qt, Gtk+`}</li>
      <li parentName="ul">{`Web: HTML, ES6+, CSS, SASS, React, Blazor`}</li>
      <li parentName="ul">{`Op maat gemaakte UI (Unity 3D)`}</li>
      <li parentName="ul">{`Statische sites via SSR, DSG (Gatsby, Next.js)`}</li>
      <li parentName="ul">{`Ervaring met internationalisatie (i18n)`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      